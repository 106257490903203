<template>
  <div class="order">
    <div class="order-containner">
      <div class="product">
        <img :src="staticUrl + order.meta.productCover" />
        <div class="sku">
          <div class="name">{{ order.meta.productName }}</div>
          <div class="price">
            {{ order.meta.skuName
            }}<span class="buy-number">数量 *{{ order.meta.buyNumber }}</span>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="input-item" v-if="!isWeixin">
        <div class="title">
          接收邮箱
        </div>
        <input v-model="email" placeholder="请输入接收点卡信息的邮箱" />
      </div>
      <div class="divider"></div>
      <div class="pay-method">
        <div class="title">支付方式</div>
        <div class="methods">
          <img src="../../assets/wechat.png" />
          <div class="name">微信支付</div>
        </div>
      </div>
    </div>
    <div class="blank-area"></div>
    <div class="footer">
      <div class="pay">
        <div class="pay-amount">
          <span class="label">支付金额：</span>
          <span class="currency">￥</span>
          <span class="amount">{{
            order.meta.price
          }}</span>
        </div>
        <button class="pay-button" @click="submitOrder">
          支付
        </button>
      </div>
    </div>
    <Toast
      :showToast="showToast"
      :message="toastMsg"
      @disappear="showToast = false"
    ></Toast>
    <Loading :display="showLoading"></Loading>
    <confirm
      :visible="confirmVisible"
      @cancel="confirmCancel"
      @confirm="payed"
    ></confirm>
  </div>
</template>
<script>
/* eslint-disable */
import { purchase, getProductsDetail, getOrderInfo } from "@/api/eshop"
import { getJsapiSign } from "@/api/common"
import Toast from "@/components/Toast"
import Loading from "@/components/Loading"
import Confirm from "@/components/Confirm"
import Zhimafen from "@/components/Zhimafen"
import debounce from "@/utils/debounce"
import { reportError } from "@/utils/log"
import wx from "weixin-js-sdk"
import { utils } from "@/utils"

export default {
  name: "Home",
  data() {
    return {
      staticUrl: utils.getStaticUrl() + "eshop/",
      showLoading: false,
      confirmVisible: false,
      toastMsg: "",
      email: "",
      showToast: false,
      order: {
        orderId: "",
        meta: {
          cover: "",
        },
      },
      isWeixin: false,
      isPC: true,
      jssdkReady: false,
    }
  },
  components: {
    Toast,
    Loading,
    Zhimafen,
    Confirm,
  },
  methods: {
    payed() {
      this.confirmVisible = false
      this.getOrderInfo(true)
    },
    confirmCancel() {
      this.confirmVisible = false
    },
    async getOrderInfo(flag = false) {
      try {
        this.showLoading = true
        let res = await getOrderInfo({
          orderId: this.orderId,
        })
        this.showLoading = false
        if (res.data.code == 0) {
          this.order = res.data.order
          if (res.data.order.status == 2) {
            // 未付款
            if (res.data.order.email) {
              // 如果是来重新付款的，需要获取订单的信息填到页面上
              this.email = res.data.order.email
            }
            if (flag) {
              this.toastMsg = "您的订单还未完成支付，请重新支付"
              this.showToast = true
            }
          } else if (res.data.order.status == 3) {
            // 付款成功
            this.$router.push("/eshop/pay-success?orderId=" + this.orderId)
          }
        }
      } catch (error) {
        reportError(error)
      }
    },
    checkMail(email) {
      return /^[0-9a-zA-Z_.+-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/.test(
        email
      )
    },
    submitOrder: debounce(async function() {
      try {
        if (!this.isWeixin) {
          if (!this.email) {
            this.toastMsg = "邮箱不能为空"
            this.showToast = true
            return
          }
          this.email = this.email.trim()
          if (!this.checkMail(this.email)) {
            this.toastMsg = "请输入正确的邮箱"
            this.showToast = true
            return
          }
        }

        this.showLoading = true
        let purchaseParams = {
          orderId: this.orderId,
          isWeixin: this.isWeixin,
        }
        if (this.email) purchaseParams['email'] = this.email
        if (this.isPC) purchaseParams["qr"] = true
        let res = await purchase(purchaseParams)
        this.showLoading = false
        if (res.data.code == 0) {
          if (this.isWeixin) {
            if (typeof WeixinJSBridge == "undefined") {
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  this.jsPay(res.data.data),
                  false
                )
              } else if (document.attachEvent) {
                document.attachEvent(
                  "WeixinJSBridgeReady",
                  this.jsPay(res.data.data)
                )
                document.attachEvent(
                  "onWeixinJSBridgeReady",
                  this.jsPay(res.data.data)
                )
              }
            } else {
              this.jsPay(res.data.data)
            }
          } else if (this.isPC) {
            let routeUrl = this.$router.resolve({
              path: "/eshop/pay-pc",
              query: {
                orderId: this.orderId,
                price: this.order.meta.price,
                skuName: this.order.meta.skuName,
                buyNumeber: this.order.meta.buyNumeber,
                productName: this.order.meta.productName,
                email: this.email,
                qrUrl: res.data.data.codeUrl,
              },
            })
            setTimeout(() => {
              this.confirmVisible = true
            }, 1500)
            window.open(routeUrl.href, "_blank")
          } else {
            window.location.href = res.data.data.url
            setTimeout(() => {
              this.confirmVisible = true
            }, 1500)
          }
        } else if (res.data.code == 2) {
          // 订单已支付
          this.$router.push("/eshop/pay-success?orderId=" + this.orderId)
        } else {
          this.toastMsg = res.data.message
          this.showToast = true
        }
      } catch (error) {
        console.log(error)
        reportError(error)
      }
    }, 200),
    jsPay(data) {
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: "wx1d62934604321a9c", //公众号名称，由商户传入
          timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: data.nonceStr, //随机串
          package: data.package,
          signType: data.signType, //微信签名方式
          paySign: data.paySign, //微信签名
        },
        (res) => {
          this.getOrderInfo()
        }
      )
    },
  },
  async created() {
    try {
      let ua = navigator.userAgent
      ua = ua.toLowerCase()
      this.isWeixin = ua.indexOf("micromessenger") !== -1
      let phoneAgents = [
        "android",
        "iphone",
        "symbianos",
        "windows phone",
        "ipad",
        "ipod",
      ]
      for (let agent of phoneAgents) {
        if (ua.indexOf(agent) !== -1) {
          this.isPC = false
          break
        }
      }
      if (this.isPC) this.isWeixin = false // 如果是PC微信端浏览器打开，就当作PC打开
      if (this.$route.query.orderid) {
        this.orderId = this.$route.query.orderid
        await this.getOrderInfo()
      } else {
        this.$router.push("/home")
      }
    } catch (error) {
      reportError(error)
    }
  },
}
</script>

<style lang="less" scoped>
.order {
  width: 7.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  .order-containner {
    width: 7.5rem;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    border-bottom: 0.02rem solid #f6f7f8;
    .divider {
      width: 100%;
      height: 0.12rem;
      background: #f6f7f8;
    }
    .product {
      width: 7.02rem;
      height: 1.8rem;
      display: flex;
      align-items: center;
      img {
        width: 1.4rem;
        height: 1.4rem;
      }
      .sku {
        margin-left: 0.18rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        font-size: 0.28rem;
        height: 1.4rem;
        .price {
          color: #666666;
          .buy-number {
            margin-left: 0.24rem;
            position: relative;
          }
        }
      }
    }
    .input-item {
      height: 0.9rem;
      width: 7.02rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 0.28rem;
        &::after {
          content: "*";
          color: red;
        }
      }
      input {
        width: 65%;
        border: none;
        height: 0.4rem;
        padding: 0.24rem 0;
        font-size: 0.28rem;
        outline: none;
        text-align: right;
        &::placeholder {
          font-size: 0.28rem;
          color: #c5c6c5;
        }
        &:focus {
          border: none;
        }
        &:active {
          border: none;
        }
        &:-moz-placeholder {
          text-align: right;
        }
        &::-moz-placeholder {
          text-align: right;
        }
        &::-webkit-input-placeholder {
          text-align: right;
        }
        &:-ms-input-placeholder {
          text-align: right;
        }
      }
      border-bottom: 0.02rem solid #f6f7f8;
      &:last-child {
        border-bottom: 0;
      }
    }
    .pay-method {
      width: 7.02rem;
      display: flex;
      height: 0.9rem;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 0.28rem;
      }
      .methods {
        display: flex;
        height: 0.9rem;
        align-items: center;
        img {
          width: 0.32rem;
          height: 0.28rem;
          margin-top: 0.04rem;
        }
        .name {
          margin-left: 0.12rem;
          font-size: 0.28rem;
        }
      }
    }
  }
  .footer {
    height: 1.1rem;
    .pay {
      border-top: 0.01rem solid #f6f7f8;
      width: 7.5rem;
      height: 1.1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .pay-amount {
        margin-left: 0.24rem;
        font-size: 0.28rem;
        .label {
          color: #050608;
        }
        .currency {
          font-size: 0.2rem;
          color: #ff4a4a;
        }
        .amount {
          font-size: 0.4rem;
          color: #ff4a4a;
        }
      }
      .pay-button {
        cursor: pointer;
        margin-right: 0.24rem;
        width: 2.2rem;
        border: none;
        border-radius: 0.4rem;
        font-size: 0.32rem;
        font-weight: bold;
        color: #ffffff;
        background: linear-gradient(90deg, #53ccaa 0%, #5bd88f 100%);
        height: 0.8rem;
        &.traffic {
          background: linear-gradient(90deg, #e67649 0%, #e5884b 100%);
        }
        &:disabled {
          background: #f6f7f8;
          color: #999999;
        }
        ::after {
          border: none;
        }
      }
    }
  }
}
</style>
